<template>
    <div
        class="events-navigation-mobile"
        :class="{
            'events-navigation-mobile--open': activeItem,
        }"
    >
        <div class="events-navigation-mobile__bar">
            <div class="events-navigation-mobile__container">
                <slot
                    :active-item="activeItem"
                    :handle-active-item-click="handleActiveItemClick"
                    name="bar"
                ></slot>
                <div
                    class="events-navigation-mobile__menu-item events-navigation-mobile__menu-item--hamburger"
                >
                    <span
                        class="hamburger"
                        :class="{ 'hamburger--open': menuActive }"
                        @click="
                            handleActiveItemClick(
                                menuActive ? false : 'menu-items'
                            )
                        "
                    >
                        <div class="hamburger__bar hamburger__bar--1"></div>
                        <div class="hamburger__bar hamburger__bar--2"></div>
                        <div class="hamburger__bar hamburger__bar--3"></div>
                    </span>
                </div>
            </div>
        </div>
        <div class="events-navigation-mobile__menu">
            <div class="events-navigation-mobile__menu-container">
                <slot v-if="showMenuItems" name="menu-items"></slot>
                <slot name="menu-cta"></slot>
                <slot
                    :active-item="activeItem"
                    :handle-active-item-click="handleActiveItemClick"
                    name="menu-quick-links"
                ></slot>
                <slot
                    :active-item="activeItem"
                    :handle-active-item-click="handleActiveItemClick"
                    name="menu-language-switcher"
                ></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
const activeItem = ref("");

const showMenuItems = computed(() => activeItem.value === "menu-items");
const menuActive = computed(
    () => activeItem.value && activeItem.value !== "menu-search"
);

const handleActiveItemClick = (item) => {
    activeItem.value = item;
};
</script>
