// eslint-disable-next-line import/no-namespace
import * as Sentry from "@sentry/vue";

const useSentry = (app) => {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],

        /**
         * https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/
         */
        trackComponents: true,

        /**
         * https://docs.sentry.io/platforms/javascript/guides/vue/tracing/
         */
        tracesSampleRate: parseFloat(
            process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE
        ),

        /**
         * Capture Replay for X % of all sessions
         * Capture Replay for X % of sessions with an error
         *
         * Sample 10% (0.1) of all sessions in production and 100% (1.0) in other environments
         * Sample 100% (1.0) of the 'sessions with an error in production and other environments
         *
         * https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/
         */
        replaysSessionSampleRate: parseFloat(
            process.env.VUE_APP_SENTRY_REPLAY_SAMPLE_RATE
        ),
        replaysOnErrorSampleRate: parseFloat(
            process.env.VUE_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE
        ),

        /**
         * Uncomment if project keeps producing this error
         * Remove if error is not produced any more
         */
        // ignoreErrors: [/Object Not Found Matching Id/],
    });
};

export default useSentry;
