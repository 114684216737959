<template>
    <div>
        <slot
            name="menu"
            :active-tab="activeTab"
            :events="{ handleClick }"
        ></slot>
        <slot :name="tabPanelSlotName"></slot>
    </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";

const props = defineProps({
    initialTab: {
        type: String,
        required: false,
        default: "",
    },
});

const activeTab = ref("");

const tabPanelSlotName = computed(() => `tab-panel-${activeTab.value}`);

const switchTab = (slug) => {
    activeTab.value = slug;
};

const handleClick = (event) => {
    switchTab(event.target.dataset.slug);
};

onMounted(() => {
    activeTab.value = props.initialTab;
});
</script>
