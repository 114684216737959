<template>
    <section class="search-bar">
        <button
            class="search-bar__button search-bar__button--text"
            :class="buttonClass"
            @click="handleActivate"
        >
            <SvgComponent name="magnify-glass" />
            <span v-if="!isMobile">{{ t("Zoeken") }}</span>
        </button>
        <Teleport v-if="isMounted" to="#search-anker">
            <ais-instant-search
                :search-client="searchClient"
                :index-name="searchIndex"
            >
                <div
                    class="search-bar__wrapper"
                    :class="{
                        'search-bar__wrapper--open': desktopSearchIsActive,
                        'search-bar__wrapper--moves-up': movesUp,
                        'search-bar__wrapper--temp-hidden': isTempHidden,
                        'search-bar__wrapper--moves-down':
                            !movesUp && desktopSearchIsActive,
                    }"
                >
                    <keep-alive>
                        <div class="search-bar__container">
                            <input
                                ref="refInput"
                                v-model="query"
                                type="text"
                                class="search-bar__input"
                                :placeholder="t('Waar ben je naar op zoek?')"
                                @keyup.enter="handleEnter"
                                @keyup.escape="handleEscape"
                            />
                            <button
                                class="search-bar__button"
                                :disabled="searchDisabled"
                                @click="handleSearch"
                            >
                                <SvgComponent
                                    name="magnify-glass"
                                    class="search-bar__icon"
                                />
                            </button>
                        </div>
                    </keep-alive>
                </div>
            </ais-instant-search>
        </Teleport>
        <Teleport to="body">
            <div
                class="search-bar__underlay"
                :class="{
                    'search-bar__underlay--open': desktopSearchIsActive,
                    'search-bar__underlay--moves-up': movesUp,
                    'search-bar__underlay--moves-down':
                        !movesUp && desktopSearchIsActive,
                }"
                @click="handleActivate"
            ></div>
        </Teleport>
    </section>
</template>
<script setup>
import { searchClient } from "../../algoliaSearchClient";
import { ref, computed, onMounted } from "vue";
import { t } from "../../filters";
import { useStore } from "vuex";

const store = useStore();

const query = ref("");
const refInput = ref();
const movesUp = ref(false);
const isMounted = ref(false);
const isTempHidden = ref(false);
const clearQuery = ref(null);

const props = defineProps({
    searchIndex: {
        type: String,
        required: true,
    },
    searchBaseUrl: {
        type: String,
        required: true,
    },
    buttonClass: {
        type: String,
        default: null,
    },
    hasScrolled: {
        type: Boolean,
        required: true,
    },
    isMobile: {
        type: Boolean,
        required: true,
    },
});

const desktopSearchIsActive = computed({
    get: () => store.state.desktopSearchState.desktopSearchIsActive,
    set: (value) => store.commit("setDesktopSearchIsActive", value),
});

const searchDisabled = computed(() => {
    return query.value.length < 3;
});

const createUrl = (query) => {
    return `${props.searchBaseUrl}?query=${encodeURIComponent(query)}`;
};

const handleSearch = () => {
    window.location.href = createUrl(query.value);
};

const handleEnter = () => {
    if (searchDisabled.value) return;
    handleSearch();
};

const handleEscape = () => {
    desktopSearchIsActive.value = false;
    query.value = "";
};

const topPosition = computed(() => {
    return props.hasScrolled ? "86px" : "122px";
});

const handleActivate = () => {
    if (!desktopSearchIsActive.value) {
        clearTimeout(clearQuery.value);
        desktopSearchIsActive.value = true;
        setTimeout(() => {
            movesUp.value = false;
            refInput.value.focus();
        }, 50);
        setTimeout(() => {
            refInput.value.focus();
        }, 550);
        return;
    }

    if (desktopSearchIsActive.value) {
        // Clear the query value after 5 sec. Beacuse of a wrong click will not automaticly clear the search.
        clearQuery.value = setTimeout(() => {
            query.value = "";
        }, 5000);
        if (!props.hasScrolled) {
            isTempHidden.value = true;
            // this is needed to set the header back to transparent
            desktopSearchIsActive.value = false;
            setTimeout(() => {
                movesUp.value = true;
                isTempHidden.value = false;
            }, 550);
        } else {
            movesUp.value = true;
        }
        setTimeout(() => {
            desktopSearchIsActive.value = false;
        }, 550);

        return;
    }
};

onMounted(() => {
    isMounted.value = true;
});
</script>
<style scoped lang="scss">
#search-anker {
    @include until-tablet {
        display: none;
    }
}

.search-bar {
    color: inherit;
    &__wrapper {
        display: none;
        position: fixed;
        background-color: white;
        left: 0;
        width: 100vw;
        height: 104px;
        opacity: 0;
        z-index: 19;
        pointer-events: none;
        transform: translateY(-105px);
        transition: transform 0.5s ease-out;
        border-top: 1px solid $grey-very-light;

        @include tablet-landscape {
            display: block;
        }

        &--moves-down {
            transform: translateY(0);
        }
        &--open {
            top: v-bind(topPosition);
            opacity: 1;
            pointer-events: all;
        }
        &--temp-hidden {
            transition: opacity 0.3s ease-out;
            transform: translateY(0);
            pointer-events: none;
            opacity: 0;
        }
    }
    &__container {
        margin: 0 auto;
        height: 104px;
        @include container;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__input {
        font-size: 48px;
        line-height: 1;
        width: 100%;
        max-width: 914px;
        border: 0;
        font-weight: 500;
        font-family: $title-font-stack;
        &::placeholder {
            color: #ccc;
            font-weight: 500;
        }
        &:active,
        &:focus {
            outline: 0;
        }
    }
    &__icon {
        width: 19px;
        height: 19px;
    }

    &__button {
        background-color: $orange;
        border: 1px solid $orange;
        color: white;
        transition: all 0.1s ease-out;
        width: 60px;
        height: 48px;
        padding: 0;
        cursor: pointer;
        &:disabled {
            color: black;
            background-color: transparent;
            border: 1px solid $black;
        }
        &--text {
            color: inherit;
            background-color: transparent;
            border: 0;
            display: flex;
            align-items: center;
            width: fit-content;
            gap: 8px;
            height: auto;
        }
    }
    &__underlay {
        @include until-tablet-landscape {
            display: none;
        }
        position: fixed;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        transition: backdrop-filter 0.5s ease-out;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        pointer-events: none;
        &--open {
            pointer-events: all;
        }
        &--moves-down {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
        }
    }
}
</style>
