<template>
    <div class="search">
        <div class="search__input-container">
            <label for="search" class="sr-only">{{ labels.placeholder }}</label>

            <svg-component
                name="search"
                class="search__icon search__icon--clickable"
                @click="handleClickIcon"
            />

            <input
                v-model="query"
                type="search"
                class="search__input"
                :placeholder="labels.placeholder"
                @keyup.enter="handleSearch"
            />
            <button
                v-if="query"
                class="search__close"
                type="button"
                @click="handleClose"
            >
                ✕
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
    searchBaseUrl: {
        type: String,
        required: true,
    },
    labels: {
        type: Object,
        required: true,
    },
});

const query = ref("");

const handleSearch = () => {
    if (query.value < 3) {
        return;
    }

    window.location.href = `${props.searchBaseUrl}?query=${query.value}`;
};

const handleClickIcon = () => {
    if (query.value) {
        handleSearch();
    }
};

const handleClose = () => {
    query.value = "";
};
</script>
