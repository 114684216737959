<template>
    <a
        :href="item.url"
        class="u-basic-reset u-flex u-width-100"
        :aria-label="getAriaLinkText(item.url)"
    >
        <div
            :class="[
                'c-overview-result',
                { 'c-overview-result--is-featured': isFeatured },
            ]"
        >
            <div class="c-overview-result__visual">
                <ImageComponent
                    :image="item.featuredImageUri"
                    :title="item.featuredImageCaption"
                    :srcset-webp="item.srcsetWebp"
                    :focal-point="item.focalPoint"
                    :loading="index < 3 ? `eager` : `lazy`"
                    :sizes="
                        isFeatured
                            ? `(max-width: 1024px) 100vw, (max-width: 1024px) 320px, 430px`
                            : `(max-width: 768px) 124px, (max-width: 1024px) 248px, (max-width: 1024px) 320px, 430px`
                    "
                    classes-image="object-fit"
                />
            </div>
            <div class="c-overview-result__content">
                <h2
                    class="c-overview-result__title"
                    :class="[
                        'c-overview-result__title',
                        {
                            'c-overview-result__title--featured': isFeatured,
                        },
                    ]"
                >
                    {{ item.title }}
                </h2>

                <div class="c-overview-result__body">
                    <p class="paragraph paragraph--limit-2">
                        {{ item.shortDescription }}
                    </p>
                </div>

                <div class="c-overview-result__footer">
                    <div class="c-overview-result__tags">
                        <PillsComponent :items="pills" />
                    </div>
                    <div
                        :class="[
                            't-note',
                            { 't-note--white-mobile': isFeatured },
                        ]"
                    >
                        {{ item.datePublished }}
                    </div>
                </div>
            </div>
            <div class="c-overview-result__icon"></div>
        </div>
    </a>
</template>

<script setup>
import { computed } from "vue";
import getAriaLinkText from "../../../helpers.js";

const props = defineProps({
    item: {
        required: true,
        type: Object,
    },
    isFeatured: {
        default: false,
        type: Boolean,
    },
    index: {
        required: true,
        type: Number,
    },
});

const pills = computed(() => {
    if (props.item.areasOfInterest) {
        return props.item.areasOfInterest.slice(0, 2).map((item) => {
            return { title: item.title, slug: item.slug };
        });
    }
    return [];
});
</script>
